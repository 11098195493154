import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { PrivaDialogButton } from '@priva/components/dialog-simple';

import { BlockingDialog } from 'app/shared';
import { AppActions, AppState } from 'app/state';

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackComponent extends BlockingDialog {
    @Input()
    public titleKey: string;

    @Input()
    public subTitleKey: string;

    @Input()
    public textKey: string;

    @Input()
    public textParams: object;

    public buttons: PrivaDialogButton[] = [
        {
            id: 'ok',
            importance: 'secondary',
            text: 'GLOBAL.ACTION.OK',
        },
    ];

    constructor(private store: Store<{ app: AppState }>) {
        super();
    }

    /* istanbul ignore next lvb, just dispatch single action */
    public close() {
        this.store.dispatch(AppActions.closeDialog({}));
    }
}
