import { Inject, Injectable } from '@angular/core';
import Dexie from 'dexie';

import { Firmware, FirmwareResources } from '@priva/next-model';

import { DEXIE_CONFIG, DexieConfiguration } from './provider/dexie.config';

@Injectable({
    providedIn: 'root',
})
export class FirmwareLocalStorageService {
    constructor(
        private db: Dexie,
        @Inject(DEXIE_CONFIG) private dexieConfiguration: DexieConfiguration,
    ) {
        this.initDB();
    }

    /* istanbul ignore next wvk, simple return */
    public async getFirmwares(): Promise<Firmware[]> {
        return this.db.table('firmware').orderBy('version').reverse().toArray();
    }

    public async getLatestFirmware(): Promise<Firmware> {
        return this.db.table('firmware').orderBy('version').reverse().first();
    }

    /* istanbul ignore next wvk, hard to test */
    public async clear(): Promise<void> {
        await this.db.table('firmware').clear();
        await this.db.table('firmwareResource').clear();
    }

    /* istanbul ignore next wvk, simple return */
    public async put(firmwares: Firmware[]): Promise<any> {
        await this.db.table('firmware').bulkPut(firmwares);
    }

    /* istanbul ignore next wvk, just init */
    private initDB(): void {
        // Name only the indexed columns in the store definition
        this.db.version(this.dexieConfiguration.databaseVersion).stores({
            firmware: 'version',
            firmwareResource: 'version',
        });
    }

    /* istanbul ignore next ec, only wrapping Dexie */
    public async exists(version: string): Promise<boolean> {
        const firmwareResource = await this.db.table('firmwareResource').get(version);
        return !!firmwareResource;
    }

    /* istanbul ignore next ec, only wrapping Dexie */
    public async getResources(version: string): Promise<FirmwareResources> {
        return this.db.table('firmwareResource').get(version);
    }

    /* istanbul ignore next ec, only wrapping Dexie */
    public async addResources(version: string, firmwareResources: FirmwareResources): Promise<any> {
        return this.db.table('firmwareResource').add({ version, ...firmwareResources });
    }
}
